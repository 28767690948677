import { SearchOptions, SearchParams, SearchParamsWithPreset } from 'typesense/lib/Typesense/Documents';
import { typesenseClient } from '../typesense';

export interface CityDto {
  name: string;
  department_code: string;
  zip_codes: string[];
  population: number;
  location: number[];
  refresh_date: number;
}


const search = (searchParameters: SearchParams | SearchParamsWithPreset, options: SearchOptions) => typesenseClient.collections<CityDto>('city').documents().search(searchParameters, options);

export default {
  search,
}