import accounts from './accounts';
import addresses from './addresses';
import brands from './brands';
import businessActivities from './business-activities';
import carts from './carts';
import categories from './categories';
import cities from './cities';
import conditionnementUnits from './conditionnement-units';
import conditionnements from './conditionnements';
import contactEmails from './contact-emails';
import contactHistories from './contact-histories';
import contactMetas from './contact-meta';
import contactOrgs from './contact-orgs';
import contactPhones from './contact-phones';
import contacts from './contacts';
import countries from './countries';
import coupons from './coupons';
import currencies from './currencies';
import docHistories from './doc-histories';
import docOverrides from './doc-overrides';
import docs from './docs';
import emailtemplates from './email-templates';
import faqEntries from './faq-entries';
import files from './files';
import flux from './flux';
import journals from './journals';
import legals from './legals';
import mapAreas from './map-areas';
import matrices from './matrices';
import me from './me';
import medias from './medias';
import newsletterSubscriptions from './newsletter-subscriptions';
import options from './options';
import organizationTypes from './organization-types';
import params from './params';
import payments from './payments';
import productCategories from './product-categories';
import productReviews from './product-reviews';
import products from './products';
import requestQuotes from './request-quotes';
import salesStats from './sales-stats';
import shipments from './shipments';
import siteReviews from './site-reviews';
import tasks from './tasks';
import transactions from './transactions';
import units from './units';
import vatRates from './vat-rates';

export type { Error, Response } from './response';

export const apiPdg = {
  accounts,
  addresses,
  brands,
  businessActivities,
  carts,
  categories,
  cities,
  conditionnements,
  contactHistories,
  contactMetas,
  contacts,
  contactEmails,
  contactPhones,
  contactOrgs,
  countries,
  coupons,
  currencies,
  docOverrides,
  docHistories,
  docs,
  emailtemplates,
  faqEntries,
  files,
  flux,
  journals,
  legals,
  mapAreas,
  matrices,
  me,
  medias,
  newsletterSubscriptions,
  organizationTypes,
  params,
  payments,
  productReviews,
  productCategories,
  products,
  requestQuotes,
  salesStats,
  shipments,
  siteReviews,
  tasks,
  transactions,
  vatRates,
  units,
  options,
  conditionnementUnits,
}