
import { elementDelete, elementGet, elementPost, filteredGetBuilder } from './response';

export enum CategoryHomepageMode {
  off = 0,
  products = 1,
  categories = 2,
}

export interface CategoryDto {
  id: number;
  parent_id: number | null;
  name: string;
  slug: string;
  description: string | null;
  meta_title: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  created: string | Date;
  modified: string | Date;
  classement: number | null;
  tree: string;
  hidden: boolean | 1 | 0;
  homepage: CategoryHomepageMode;
  homepage_parent_id: number | null;
  hide_facets: boolean | 1 | 0;
  brand_facet: boolean | 1 | 0;
  parent: string | null;
  // Uniquement dans certaines circonstances...
  parents?: CategoryRelatedDto[];
  children?: CategoryRelatedDto[];
  homepage_children?: CategoryRelatedDto[];
}

export interface CategoryRelatedDto {
  id: number;
  parent_id: number | null;
  name: string;
  slug: string;
  hidden?: 1 | 0 | boolean;
  homepage?: CategoryHomepageMode;
}

export interface CategoryOptionDto {
  id: number;
  display_order: number | null;
  product_mode: boolean | 1 | 0 | null;
}

export const list = filteredGetBuilder<CategoryDto>(`backend/categories`);
export const get = (id: number) => elementGet<CategoryDto>(`backend/categories/${id}`);
export const add = (data: Partial<CategoryDto>) => elementPost<{ id: number }>(`backend/categories`, { json: data });
export const update = (id: number, data: Partial<CategoryDto>) => elementPost<never>(`backend/categories/${id}`, { json: data });
export const remove = (categoryId: number) => elementDelete<never>(`backend/categories/${categoryId}`);
export const listOptions = (categoryId: number, productSheet: boolean) => elementGet<CategoryOptionDto[]>(`backend/categories/${categoryId}/options?product_sheet=${productSheet}`);
export const removeOption = (categoryId: number, optionId: number) => elementDelete<never>(`backend/categories/${categoryId}/options/${optionId}`);
export const addOption = (categoryId: number, data: { options: Partial<CategoryOptionDto>[] }) => elementPost<never>(`backend/categories/${categoryId}/options`, { json: data });
export const updateOption = (categoryId: number, optionId: number, data: Partial<CategoryOptionDto>) => elementPost<never>(`backend/categories/${categoryId}/options/${optionId}`, { json: data });

export default {
  list,
  get,
  remove,
  add,
  update,
  options: {
    list: listOptions,
    add: addOption,
    remove: removeOption,
    update: updateOption,
  },
};