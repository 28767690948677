import { DeleteQuery, DeleteResponse, SearchOptions, SearchParams, SearchParamsWithPreset } from 'typesense/lib/Typesense/Documents';
import { apiHttp } from '../api-http';
import { typesenseClient } from '../typesense';

export type GeoPoint = [number, number];

export interface MapAreaDto {
  id?: number;
  label?: string;
  shape: 'polygon' | 'circle' | 'rectangle';
  lat_lng?: GeoPoint;
  lat_lngs?: GeoPoint[];
  radius?: number;
  visible: boolean;
  user_id: number;
}


const search = (searchParameters: SearchParams | SearchParamsWithPreset, options: SearchOptions) => typesenseClient.collections<MapAreaDto>('map_area').documents().search(searchParameters, options);
// const create = (document: MapAreaDto) => typesenseClient.collections<MapAreaDto>('map_area').documents().create(document);

// const upsert = (document: MapAreaDto) => typesenseClient.collections<MapAreaDto>('map_area').documents().upsert(document);
const upsert = (document: MapAreaDto) => apiHttp.post<MapAreaDto>('backend/map-areas', { json: document }).json();

// const remove = (query?: DeleteQuery) => typesenseClient.collections<MapAreaDto>('map_area').documents().delete(query);
const remove = (query?: DeleteQuery) => apiHttp.delete<DeleteResponse>('backend/map-areas', { json: query }).json();

export default {
  search,
  // create,
  upsert,
  delete: remove,
}